(function($, window, document) {
    "use strict";
    $(function() {
        //##Variables
        var $body = $('body'),
	        $header = $('#header'),
            $searchTrigger = $('#search-icon'),
            $searchTriggerMob = $('#search-icon-mob'),
            $formHolder = $('.form_holder'),
            $nav = $('.nav_wrapper'),
            $mobileMenuTrigger = $('#mobile_menu_trigger'),
            $window = $(window),
            $doc = $(document),
            defaultEasing = [0.4, 0.0, 0.2, 1];
        //End Variables
        $mobileMenuTrigger.on('click', function(e){
            $nav.toggleClass('is--active');
            $(this).toggleClass('is--active');
        });
        //Header scroll animation
        $doc.scroll(function() {
            if ($doc.scrollTop() >= $header.outerHeight()) {
                $body.addClass('is--sticky--header');
            } else {
                $body.removeClass('is--sticky--header');
            }
        }); 
        
        $('.close_ad').on('click', function(e){
            $(this).parent().hide();
        }); 

        $searchTrigger.on('click', function(e){
            $body.toggleClass('search-toggle');
            $formHolder.toggleClass('is--active');
        }); 
        $searchTriggerMob.on('click', function(e){
            $body.toggleClass('search-toggle');
            $formHolder.toggleClass('is--active');
        });

        $('.owl-carousel').owlCarousel({
            loop: true,
		    margin:20,
		    nav:true,
			autoplayTimeout: 5000,
		    autoplay: true,
		    responsive:{
		        0:{
		            items:1,
					slideBy: 1
		        },
		        768:{
		            items:2,
					slideBy: 2
		        }
		    }
    	});
    });
}(window.jQuery, window, document));
